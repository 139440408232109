









































































































































import { computed, defineComponent, watch } from '@vue/composition-api';
import sz_lang from '@helpers/translate';

export default defineComponent({
  name: 'RequestFields',
  props: {
    showHeaders: {
      type: Boolean,
      default: true
    },
    showParams: {
      type: Boolean,
      default: true
    },
    showBody: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    params: {
      type: Array,
      default: () => []
    },
    body: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();

    const itemAccordion = computed(() => {
      const options = [
        {
          icon: 'fas fa-plus-circle fa-sm',
          label: translate.value.header,
          identifier: 'header',
          dense: true,
          visible: props.showHeaders,
          'header-class': 'bg-grey-3 text-grey'
        },
        {
          icon: 'fas fa-plus-circle fa-sm',
          label: translate.value.parameter,
          identifier: 'parameter',
          visible: props.showParams,
          dense: true,
          'header-class': 'bg-grey-3 text-grey'
        },
        {
          icon: 'fas fa-plus-circle fa-sm',
          label: translate.value.body,
          identifier: 'body',
          visible: props.showBody,
          dense: true,
          'header-class': 'bg-grey-3 text-grey'
        }
      ];

      return options.filter(item => item.visible);
    });

    const columns = computed(() => {
      return [
        { label: translate.value.parameter, align: 'left', field: 'name' },
        { label: translate.value.value, align: 'center', field: 'value' },
        { label: translate.value.action, align: 'center', field: 'action' }
      ];
    });

    const simpleTable = computed(() => {
      return {
        headers: {
          columns: columns.value,
          data: [...props.headers]
        },
        params: {
          columns: columns.value,
          data: [...props.params]
        },
        body: {
          columns: columns.value,
          data: [...props.body]
        }
      };
    });

    watch(
      () => props.headers,
      value => {
        emit('update:headers', value);
      },
      {
        deep: true
      }
    );

    watch(
      () => props.params,
      value => {
        emit('update:params', value);
      },
      {
        deep: true
      }
    );

    watch(
      () => props.body,
      value => {
        emit('update:body', value);
      },
      {
        deep: true
      }
    );

    // METHODS
    const addHeaders = () => {
      props.headers.push({ name: '', value: '' });
    };

    const addParams = () => {
      props.params.push({ name: '', value: '' });
    };

    const addBody = () => {
      props.body.push({ name: '', value: '' });
    };

    const removeHeaders = (index: number) => {
      props.headers.splice(index, 1);
    };

    const removeParams = (index: number) => {
      props.params.splice(index, 1);
    };

    const removeBody = (index: number) => {
      props.body.splice(index, 1);
    };

    return {
      simpleTable,
      itemAccordion,
      addHeaders,
      addParams,
      addBody,
      removeHeaders,
      removeParams,
      removeBody
    };
  }
});
