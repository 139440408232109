

























import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  PropType
} from '@vue/composition-api';
import sz_lang from '@helpers/translate';
import { TeamModule } from '@helpers/vuex-composition';
import { Iselect } from '@models/general';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    teamsOptions: {
      type: Array as PropType<Iselect[]>,
      default: () => []
    }
  },

  setup(props, { emit }) {
    const { translate } = sz_lang();
    const { listSelectTeams, loadingSelect } = TeamModule.useState([
      'listSelectTeams',
      'loadingSelect'
    ]);
    const { SEARCH_TEAMS, VIRTUAL_SCROLL_TEAMS } = TeamModule.useActions([
      'SEARCH_TEAMS',
      'VIRTUAL_SCROLL_TEAMS'
    ]);

    const state = reactive({
      search: ''
    });

    onMounted(() => {
      if (Array.isArray(model.value)) {
        model.value.forEach(id => {
          if (!existsInOptions(id)) SEARCH_TEAMS(id, 'SEARCH_TEAMS_BY_ID');
        });
      } else {
        if (!existsInOptions(model.value))
          SEARCH_TEAMS(model.value, 'SEARCH_TEAMS_BY_ID');
      }
    });

    const existsInOptions = id => {
      return props.teamsOptions.some(team => team.value.toLowerCase() == id);
    };

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      }
    });

    const labelSelect = computed(() => {
      if (props.label) {
        return props.label;
      }

      return translate.value.team;
    });

    const searchTeam = async (search: string, update: any) => {
      state.search = search;
      if (search.length > 3) {
        await SEARCH_TEAMS(search);
      }
      update(() => {
        listSelectTeams.value;
      });
    };

    return {
      ...toRefs(state),
      model,
      labelSelect,
      loadingSelect,
      VIRTUAL_SCROLL_TEAMS,
      searchTeam
    };
  }
});
