import { Icommon } from '@models/general';

export const state: Icommon = {
  submitting: false,
  showAlert: false,
  messageAlert: '',
  isAlertError: false,
  btnSubmitDisable: false,
  isForgotPasswordSuccess: false,
  showRecaptchaV2: false,
  send: {
    email: '',
    language: '',
    password: '',
    recaptchaV3Token: '',
    recaptchaV2Token: ''
  }
};
