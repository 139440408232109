<template>
  <q-toggle
    v-model="Model"
    :name="name"
    :label="label"
    :disable="disable"
    :dark="dark"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <slot />
  </q-toggle>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Toggle',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const Model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      },
    });

    return { Model };
  },
});
</script>
