









import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ColorPicker',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    defaultView: {
      type: String,
      default: 'spectrum'
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      }
    });

    return {
      model
    };
  }
});
