




































import { defineComponent, PropType } from '@vue/composition-api';
import { Ibreadcrumb } from '@models/general';

export default defineComponent({
  name: 'Breadcrumb',
  props: {
    help: {
      type: String,
      default: null
    },
    disableHelp: {
      type: Boolean,
      default: true
    },
    showHelp: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array as () => PropType<Ibreadcrumb>,
      default: () => []
    }
  }
});
