






import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Icon',
  props: {
    tag: {
      type: String,
      default: 'i'
    },
    name: {
      type: String,
      default: ''
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const settings = computed(() => {
      return { ...props };
    });

    return { settings };
  }
});
