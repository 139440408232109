<template>
  <twemoji-picker
    :emojiData="emojiDataAll"
    :emojiGroups="emojiGroups"
    @emojiUnicodeAdded="selectEmoji"
    ref="showPicker"
    :picker-width="350"
  >
    <template v-slot:twemoji-picker-button>
      <sz-btn icon="far fa-smile fa-lg" size="sm" flat round></sz-btn>
    </template>
  </twemoji-picker>
</template>
<script>
import '@css/components/emojiPicker.scss';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { TwemojiPicker } from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';

export default defineComponent({
  name: 'EmojiPicker',
  components: {
    TwemojiPicker
  },
  setup(props, { emit }) {
    const showPicker = ref();
    const removeGroups = [2];

    const emojiDataAll = computed(() => {
      return EmojiAllData.filter(({ group }) => {
        return removeGroups.indexOf(group) === -1;
      });
    });

    const emojiGroups = computed(() => {
      return EmojiGroups.filter(({ group }) => {
        return removeGroups.indexOf(group) === -1;
      });
    });

    const selectEmoji = emoji => {
      showPicker.value.isPickerOpen = false;
      emit('emoji', emoji);
    };

    return { selectEmoji, showPicker, emojiDataAll, emojiGroups };
  }
});
</script>
