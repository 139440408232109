import { getUserCache } from '@helpers/cache';
import { Icommon } from '@models/general';
import { inject } from '@vue/composition-api';

let unleash;

export default function useUnleash() {
  if (unleash) {
    return unleash;
  }

  unleash = inject('unleash') as Icommon;

  const userData = getUserCache() as Icommon;
  const client_id = userData.client_id;
  const host = window.location.host;

  unleash.on('ready', () => {
    unleash.updateContext('client_id', client_id);
    unleash.updateContext('userId', userData.user._id);
    unleash.updateContext('email', userData.user.email);
    unleash.setContextField('host', host);
  })

  return unleash;
}