






























import sz_lang from '@helpers/translate';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs
} from '@vue/composition-api';
import { FlowModule } from '@helpers/vuex-composition';
import { Icommon, Iselect } from '@models/general';

export default defineComponent({
  name: 'SelectFlow',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: ''
    },
    objectValueFlow: {
      type: Object,
      default: () => {
        return {};
      }
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();
    const selectFlowRef = ref();

    const { listSelectflows, loadingSelect } = FlowModule.useState([
      'listSelectflows',
      'loadingSelect'
    ]);

    const {
      SEARCH_FLOWS,
      VIRTUAL_SCROLL_FLOWS,
      GET_LIST_SELECT_FLOWS
    } = FlowModule.useActions([
      'SEARCH_FLOWS',
      'VIRTUAL_SCROLL_FLOWS',
      'GET_LIST_SELECT_FLOWS'
    ]);

    const { ADD_ITEM_SELECT_FLOWS } = FlowModule.useMutations([
      'ADD_ITEM_SELECT_FLOWS'
    ]);

    const state = reactive({
      search: ''
    });

    onMounted(() => {
      GET_LIST_SELECT_FLOWS();
      // caso o valor não esteja na lista isso tem como objetivo de alimentar a lista com o item selecionado
      if (props.objectValueFlow?._id) {
        addListIfNotExist(props.objectValueFlow?._id);
      }
    });

    const model = computed({
      get() {
        return props.value;
      },
      set(value: string) {
        emit('input', value);
      }
    });

    const flowOptions = computed(() => {
      let listFlows = listSelectflows.value.filter((item: Icommon) => {
        return item.name.toLowerCase().includes(state.search.toLowerCase());
      });

      return listFlows.map(
        (item: Icommon): Iselect => {
          return {
            label: item.name,
            value: item._id
          };
        }
      );
    });

    const addListIfNotExist = (flowId: string) => {
      let index = listSelectflows.value.findIndex((item: Icommon) => {
        return item._id === flowId;
      });

      if (index === -1) {
        ADD_ITEM_SELECT_FLOWS(props.objectValueFlow);
      }
    };

    const searchFlow = async (search: string, update: any) => {
      state.search = search;

      if (search.length > 3) {
        await SEARCH_FLOWS(search);
      }
      update(() => {
        listSelectflows.value;
      });
    };

    return {
      model,
      translate,
      flowOptions,
      loadingSelect,
      searchFlow,
      VIRTUAL_SCROLL_FLOWS,
      selectFlowRef,
      ...toRefs(state)
    };
  }
});
