




















































import sz_lang from '@helpers/translate';
import { Ipermission } from '@models/model';
import {
  computed,
  defineComponent,
  reactive,
  PropType,
  toRefs
} from '@vue/composition-api';

export default defineComponent({
  name: 'Search',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    permission: {
      type: Object as PropType<Ipermission>,
      default: () => {
        return {
          add: true,
          remove: true
        };
      }
    },
    value: {
      type: String,
      default: ''
    },
    showPlus: {
      type: Boolean,
      default: true
    },
    showTrash: {
      type: Boolean,
      default: true
    },
    disablePlus: {
      type: Boolean,
      default: false
    },
    disableTrash: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();
    const state = reactive({
      showLabel: true,
      model: computed({
        get() {
          return props.value;
        },
        set(value: string) {
          emit('input', value);
        }
      }),
      text: {
        search: translate.value.search
      }
    });

    const visibleLabel = (value: boolean) => {
      state.showLabel = value;
    };

    return { ...toRefs(state), translate, visibleLabel };
  }
});
