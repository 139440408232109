






















import { Iaccordion } from '@models/general';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'Accordion',
  props: {
    rounded: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    },
    contentClass: {
      type: String,
      default: ''
    },
    inputStyle: {
      type: String,
      default: ''
    },
    items: {
      type: Array as () => PropType<Iaccordion>,
      default: () => []
    }
  }
});
