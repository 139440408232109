





























import { computed, defineComponent, PropType } from '@vue/composition-api';
import { languageList } from '@helpers/utils';
import sz_lang from '@helpers/translate';
import { Ilanguage } from '@models/general';

export default defineComponent({
  name: 'SelectLanguage',

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'lang'
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array as PropType<Ilanguage[]>,
      default: () => []
    },
    outlined: Boolean
  },

  setup(props, { emit }) {
    const { translate } = sz_lang();

    const labelSelect = computed(() => {
      if (props.label) {
        return props.label;
      }

      return translate.value.language;
    });

    const settingsOptions = computed(() => {
      if (props.options.length > 0) {
        return props.options;
      }

      return languageList();
    });

    const model = computed({
      get() {
        return props.value;
      },
      set(value: string) {
        emit('input', value);
      }
    });

    const flag = computed(() => {
      const result = settingsOptions.value.find(
        ({ value }) => value === model.value
      );

      if (result?.flag) {
        return result.flag;
      }

      return '';
    });

    return {
      labelSelect,
      settingsOptions,
      model,
      flag
    };
  }
});
