












































import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  PropType,
  computed,
  onMounted
} from '@vue/composition-api';
import { Icommon, IdefinePlatform } from 'src/settings/models/general';
import NoData from './NoData.vue';
import sz_lang from '@helpers/translate';
import { getPermissionCache } from '@helpers/cache';
import {
  platformBlockedDefault,
  platformBlockedRemoteSupport
} from '@helpers/platforms';

export default defineComponent({
  components: { NoData },

  name: 'DropdownChannel',

  props: {
    platforms: {
      type: Array as PropType<Icommon[]>,
      required: true,
      default: () => []
    },
    permission: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'sm'
    },
    flat: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    fit: {
      type: Boolean,
      default: true
    },
    round: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'fas fa-plus'
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();
    const platformMenu = ref();
    const state: Icommon = reactive({
      search: '',
      listPermission: {},
      text: {
        no_result: translate.value.messages.no_result,
        search: translate.value.search
      }
    });

    onMounted(() => {
      state.listPermission = getPermissionCache();
    });

    const listItems = computed(() => {
      let platformBlocked = platformBlockedDefault();

      if (state.listPermission.supportRemote) {
        platformBlocked = platformBlockedRemoteSupport();
      }

      return props.platforms.filter((item: Icommon) => {
        return platformBlocked.indexOf(item.platform) === -1;
      });
    });

    const setSelect = (item: IdefinePlatform): void => {
      state.search = '';
      platformMenu.value.hide();
      emit('set', item);
    };

    return { ...toRefs(state), setSelect, platformMenu, listItems, translate };
  }
});
