






















import {
  computed,
  defineComponent,
  WritableComputedRef
} from '@vue/composition-api';

export default defineComponent({
  name: 'Profile',
  props: {
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'blue'
    },
    itemClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    
    const getName: WritableComputedRef<string> = computed(() => {
      return props.name;
    });

    const getEmail: WritableComputedRef<string> = computed(() => {
      return props.email;
    });

    const getColor: WritableComputedRef<string> = computed(() => {
      return props.color;
    });

    const getSrc: WritableComputedRef<string> = computed(() => {
      return props.src;
    });

    return { getName, getEmail, getSrc, getColor };
  }
});
