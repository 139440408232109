





































import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  PropType
} from '@vue/composition-api';
import { Icommon, Iselect } from '@models/general';
import sz_lang from '@helpers/translate';
import { ChannelModule } from '@helpers/vuex-composition';
const { translate } = sz_lang();

export default defineComponent({
  name: 'SelectChannels',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    platforms: {
      type: Array as PropType<Icommon[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      search: ''
    });

    const { loadingSelect, listChannels } = ChannelModule.useState([
      'loadingSelect',
      'listChannels'
    ]);

    const {
      GET_LIST_SELECT_CHANNELS,
      VIRTUAL_SCROLL_SELECT_CHANNELS,
      SEARCH_SELECT_CHANNELS
    } = ChannelModule.useActions([
      'GET_LIST_SELECT_CHANNELS',
      'VIRTUAL_SCROLL_SELECT_CHANNELS',
      'SEARCH_SELECT_CHANNELS'
    ]);

    onMounted(async () => {
      await GET_LIST_SELECT_CHANNELS();
    });

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      }
    });

    const channelsOptions = computed(() => {
      let options = listChannels.value.filter((item: Icommon) => {
        const search = state.search.toLowerCase();
        return item.description.toLowerCase().includes(search);
      });

      return options.map(
        (item: Icommon): Iselect => {
          return {
            icon: getIconPlatform(item.platform),
            label: item.description,
            value: item._id
          };
        }
      );
    });

    const getIconPlatform = (platform: string): string => {
      const platforms: Icommon[] = props.platforms;
      const res = platforms.find(
        (item: Icommon) => item?.platform === platform
      );

      if (res?.icon) {
        return res?.icon;
      }

      return '';
    };

    const searchChannels = async (search: string, update: any) => {
      state.search = search;

      if (search.length > 3) {
        await SEARCH_SELECT_CHANNELS(search);
      }
      update(() => {
        listChannels.value;
      });
    };

    return {
      model,
      translate,
      channelsOptions,
      searchChannels,
      loadingSelect,
      VIRTUAL_SCROLL_SELECT_CHANNELS
    };
  }
});
