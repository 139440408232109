








import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [File, FileList, Array],
      default: null,
    },
  },
  setup(props, { emit }) {
    const qFile = ref();
    const model = computed({
      get() {
        return props.value;
      },
      set(value: any) {
        emit('input', value);
      },
    });

    return { model, qFile };
  },
});
