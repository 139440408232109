













import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Badge',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    transparent: {
      type: Boolean,
      default: false
    },
    floating: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'middle'
    }
  }
});
