<template>
  <div id="announcement" :class="setColor">
    <a
      :href="link.url ? link.url : null"
      :target="link.internal ? null : '_blank'"
      style="text-decoration: none;"
    >
      <q-toolbar class="text-white sz-notify-alert" >
        <div class="row">
          <p class="no-margin">
            <b>
              <icon name="fas fa-bell" />
              {{ translate.announcement }}:
            </b>
            {{ message }}
          </p>
        </div>
      </q-toolbar>
    </a>
    <sz-btn
      size="sm"
      class="btn-close"
      @click="$emit('close')"
      flat
      round
      icon="fas fa-times"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import sz_lang from '@helpers/translate';
export default defineComponent({
  name: 'Announcement',
  props: {
    color: {
      type: String,
      default: 'info',
      validator: val => {
        const options = ['info', 'danger', 'warning', 'success', 'neutral'];
        return !!(options.indexOf(val) !== -1);
      }
    },
    link: {
      type: Object,
      default: () => {
        return {};
      }
    },
    message: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { translate } = sz_lang();

    const setColor = computed(() => {
      switch (props.color) {
        case 'info':
          return 'alert-info';
        case 'success':
          return 'alert-success';
        case 'danger':
          return 'alert-danger';
        case 'warning':
          return 'alert-warning';
        case 'neutral':
          return 'alert-neutral';
      }
    });

    return { translate, setColor };
  }
});
</script>

<style lang="scss" scoped>
#announcement {
  box-shadow: 0 0 25px -6px #000;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.alert-info {
  background-color: #4084e9;
}

.alert-warning {
  background-color: #ec971f;
}

.alert-success {
  background-color: #377937;
}

.alert-danger {
  background-color: #b02a35;
}

.alert-neutral {
  background-color: #757272;
}

#announcement > a {
  display: inline-block;
  font-size: 15px;
}

.btn-close {
  font-size: 10px;
  color: #fff;
  margin: 10px;
}
</style>
