import { Icommon } from '@models/general';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';

const StorageModule: Module<Icommon, any> = {
  namespaced: true,
  actions,
  mutations
};
export default StorageModule;
