



























import {
  computed,
  defineComponent,
  reactive,
  PropType
} from '@vue/composition-api';
import sz_lang from '@helpers/translate';
import { InstagramModule } from '@helpers/vuex-composition';
import { Icommon, Iselect } from '@models/general';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: ''
    },
    options: {
      type: Array as PropType<Icommon[]>,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();

    const state = reactive({
      search: ''
    });

    const { loadingSelect } = InstagramModule.useState(['loadingSelect']);

    const model = computed({
      get() {
        return props.value;
      },
      set(value: string) {
        emit('input', value);
      }
    });

    const reclameAquiOptions = computed(() => {
      let options = props.options.filter((item: Icommon) => {
        return item.name.toLowerCase().includes(state.search.toLowerCase());
      });

      return options.map(
        (item: Icommon): Iselect => {
          return {
            label: item.name,
            value: item.companyId
          };
        }
      );
    });

    const searchPageReclameAqui = (search: string, update: any) => {
      state.search = search;
      update(() => {
        reclameAquiOptions.value;
      });
    };

    return {
      model,
      translate,
      reclameAquiOptions,
      loadingSelect,
      searchPageReclameAqui
    };
  }
});
