













import { Istep } from '@models/general';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    items: {
      type: Array as PropType<Istep[]>,
      default: () => []
    },
    animated: {
      type: Boolean,
      default: true
    },
    headerNav: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      }
    });

    return { model };
  }
});
