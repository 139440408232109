<template>
  <icon name="fas fa-ban" color="red" class="cursor-pointer">
    <sz-tooltip> {{ translate.without_permission }} </sz-tooltip>
  </icon>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import sz_lang from '@helpers/translate';

export default defineComponent({
  name: 'WithoutPermission',
  setup() {
    const { translate } = sz_lang();

    return { translate };
  }
});
</script>
