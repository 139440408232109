import { notification } from '@helpers/utils';
import { query, mutation } from '@graphql';
import { $apollo } from '@boot/apollo';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';

export const actions: ActionTree<Icommon, any> = {
  async GET_REQUEST_MARKETPLACE({ commit }): Promise<void> {
    commit('SET_LOADING_SEARCH', true);
    await $apollo
      .query({
        query: query.GET_INTEGRATIONS
      })
      .then(({ data: { getIntegrations } }) => {
        commit('SET_LIST_MARKETPLACE', getIntegrations);
      })
      .finally(() => {
        commit('SET_LOADING_SEARCH', false);
      })
      .catch(() => {
        notification('error');
      });
  },
  async ENABLE_INTEGRATION({ dispatch }, params: Icommon): Promise<void> {
    await $apollo
      .mutate({
        mutation: mutation.SAVE_INTEGRATIONS,
        variables: { integrations: { ...params } }
      })
      .then(
        async (): Promise<void> => {
          notification('success');
          await dispatch('GET_REQUEST_MARKETPLACE');
        }
      )
      .catch(() => {
        notification('error');
      });
  }
};
