import { Icommon } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  SET_LIST_PAGES_DIRECT(state, value) {
    state.listPagesDirect = value;
  },
  SET_LIST_PAGES_COMMENTS(state, value) {
    state.listPagesComments = value;
  }
};
