<template>
  <img class="logo" :src="src" alt="logo" />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import logo from '@img/logo_szchat.svg';
import { getStorage } from '@helpers/utils';

export default defineComponent({
  name: 'Logo',

  setup() {
    const src = computed(() => {
      const { brand } = getStorage('sz_whitelabel');

      if (brand) {
        return brand;
      }

      return logo;
    });

    return { src };
  }
});
</script>

<style lang="scss">
.logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 51px;
}
</style>
