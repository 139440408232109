















































import sz_lang from '@helpers/translate';
import { Icommon } from '@models/general';
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  WritableComputedRef
} from '@vue/composition-api';

export default defineComponent({
  name: 'Pagination',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    pagination: {
      type: Object,
      required: true
    },
    showRowPage: {
      type: Boolean,
      default: true
    },
    value: {
      type: Number,
      default: () => 0
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();
    const state = reactive({
      select_current_page: props.pagination.currentPage,
      text: {
        rows_page: translate.value.rows_per_page
      }
    });

    // Computed
    const limit: WritableComputedRef<number> = computed(() => {
      return props.pagination.itemsPerPage;
    });

    const selectRowpage = computed({
      get() {
        return state.select_current_page;
      },
      set(value: number) {
        state.select_current_page = value;
      }
    });

    const totalPages: WritableComputedRef<number> = computed(() => {
      return props.pagination.totalPages;
    });

    const totalItems: WritableComputedRef<number> = computed(() => {
      return props.pagination.totalItems;
    });

    const countValue: WritableComputedRef<number> = computed(() => {
      if (props.value * limit.value > totalItems.value) {
        return totalItems.value;
      } else {
        return props.value * limit.value;
      }
    });

    const config = computed(() => {
      let options: Icommon = [];
      let i = 0;
      let loopOptions = Math.ceil(totalItems.value / 10);

      while (i < loopOptions) {
        i++;
        let count = i * 10;

        if (totalItems.value < count) {
          options.push({ label: totalItems.value, value: i });
        } else {
          options.push({ label: count, value: i });
        }
      }

      return {
        maxItem: totalPages.value,
        totalPages,
        options
      };
    });

    const currentPage = computed({
      get() {
        return props.value;
      },
      set(value: number) {
        emit('input', value);
      }
    });

    watch(
      () => props.pagination,
      value => {
        emit('input', value.currentPage);
      },
      {
        deep: true
      }
    );

    // Methods
    const changeSelectOptions = (page: number) => {
      const res = config.value.options.find(item => item.value === page);
      emit('update:limit', res.label);
      emit('request', { page: 1 });
    };

    const changePaginationPage = () => {
      emit('update:limit', limit.value);
      emit('request', { page: props.value });
    };

    return {
      ...toRefs(state),
      currentPage,
      config,
      countValue,
      translate,
      selectRowpage,
      changeSelectOptions,
      changePaginationPage
    };
  }
});
