

































































import { Notify } from 'quasar';
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  WritableComputedRef
} from '@vue/composition-api';
import { Icommon, Iselect } from 'src/settings/models/general';
import sz_lang from 'src/helpers/translate';
import { formValidate } from 'src/helpers/validate';

export default defineComponent({
  name: 'SelectAuthenticationMethod',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    showJwt: {
      type: Boolean,
      default: true
    },
    showOauth: {
      type: Boolean,
      default: true
    },
    showBasic: {
      type: Boolean,
      default: true
    },
    showAuthMethod: {
      type: Boolean,
      default: false
    },
    showIsRefreshToken: {
      type: Boolean,
      default: false
    },
    configFields: {
      type: Object,
      default() {
        return {};
      }
    },
    auth_method: {
      type: String,
      default: 'none'
    },
    fields: {
      type: Object,
      default: () => {
        return {};
      }
    },
    channel_id: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();

    const state = reactive({
      text: {
        image_size: translate.value.messages.image_size
      },
      formRules: formValidate(),
      send: {
        auth_method: props.auth_method || 'none',
        basic: {
          url: props.fields?.url || null,
          username: props.fields?.username || null,
          password: props.fields?.password || null
        },
        jwt: {
          serviceAccountFile: props.fields?.serviceAccountFile || null,
          email: props.fields?.email || null,
          key: props.fields?.key || null,
          keyId: props.fields?.keyId || null
        },
        oauth2: {
          client_id: props.fields?.client_id || null,
          client_secret: props.fields?.client_secret || null,
          isRefreshToken: props.fields?.isRefreshToken || false,
          url_exchange_token: props.fields?.url_exchange_token || null,
          url_refresh_token: props.fields?.url_refresh_token || null,
          url_request_token: props.fields?.url_request_token || null
        }
      }
    });

    async function convertFileToJson(file: File): Promise<Icommon> {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = (function() {
          return function(e: Icommon) {
            try {
              const json = JSON.parse(e.target.result);
              resolve(json);
            } catch (ex) {
              console.error('execption when trying to parse json');
              reject(ex);
            }
          };
        })();
        reader.readAsText(file);
      });
    }

    watch(
      () => state.send.jwt.serviceAccountFile,
      async value => {
        const {
          client_email,
          private_key,
          private_key_id
        } = await convertFileToJson(value);

        state.send.jwt.email = client_email;
        state.send.jwt.key = private_key;
        state.send.jwt.keyId = private_key_id;
      }
    );

    watch(
      () => state.send,
      value => {
        emit('update:auth', {
          auth_method: value.auth_method,
          ...state.send[value.auth_method]
        });
      },
      { deep: true }
    );

    watch(
      () => state.send.oauth2.client_id,
      value => {
        emit('update:client_id', value);
      }
    );

    watch(
      () => state.send.oauth2.client_secret,
      value => {
        emit('update:client_secret', value);
      }
    );

    const configAuth = computed(() => {
      let configAuth = {
        jwt: {
          select: { label: 'JWT', value: 'jwt', show: props.showJwt },
          fields: []
        },
        oauth2: {
          select: { label: 'OAuth 2', value: 'oauth2', show: props.showOauth },
          fields: [
            { label: 'client_id', type: 'text', name: 'client_id', show: true },
            {
              label: 'client_secret',
              type: 'text',
              name: 'client_secret',
              show: true
            },
            {
              label: 'url_request_token',
              type: 'text',
              name: 'url_request_token',
              show: true
            },
            {
              label: 'url_exchange_token',
              type: 'text',
              name: 'url_exchange_token',
              show: true
            },
            {
              label: 'url_refresh_token',
              type: 'text',
              name: 'url_refresh_token',
              show: true
            }
          ]
        },
        basic: {
          select: { label: 'Basic', value: 'basic', show: props.showBasic },
          fields: [
            { label: 'url', type: 'text', name: 'url', show: true },
            { label: 'username', type: 'text', name: 'username', show: true },
            {
              label: 'password',
              type: 'password',
              name: 'password',
              show: true
            }
          ]
        }
      };

      // trata a propriedade configFields
      if (props.configFields?.method) {
        const { method, fields } = props.configFields;

        // remove os campos passados pelo configFields do array configAuth
        fields.map(customField => {
          configAuth[method].fields = configAuth[method].fields.filter(
            field => field.name !== customField.name
          );
        });

        // junta configAuth com configFields
        configAuth[method].fields = [...configAuth[method].fields, ...fields];
      }

      return configAuth;
    });

    const confirmOptions = computed(() => {
      return [
        { label: translate.value.yes, value: true },
        { label: translate.value.no, value: false }
      ];
    });

    const authOptions: WritableComputedRef<Iselect[]> = computed(() => {
      let options = [] as any;
      if (state.send.auth_method !== 'oauth2')
        options.push({ label: translate.value.none, value: 'none' });
      if (state.send.auth_method != 'basic')
        options.push({ label: translate.value.none, value: 'none' });
      Object.values(configAuth.value).forEach((item: Icommon) => {
        if (item.select.show) {
          options.push(item.select);
        }
      });

      return options;
    });

    const authFields = computed(() => {
      const value = state.send.auth_method;
      if (value && value !== 'none') {
        return configAuth.value[value].fields;
      }
      return [];
    });

    const infoAuth = computed(() => {
      const result = authOptions.value.find(options => {
        return options.value === state.send.auth_method;
      });

      if (result?.label) {
        return result.label;
      }

      return '';
    });

    const isOauth = computed(() => {
      return state.send.auth_method === 'oauth2';
    });

    const isJWT = computed(() => {
      return state.send.auth_method === 'jwt';
    });

    function checkFileType(files) {
      return files.filter(file => file.type === 'application/json');
    }

    function onFileRejected() {
      Notify.create({
        type: 'negative',
        message: translate.value.errors.only_json_file,
        position: 'top-right',
        progress: true
      });
    }

    const formRulesConfig = (name: string, channel_id: any, rules: any) => {
      const fieldsEmpty = ['client_secret', 'username', 'password'];

      return fieldsEmpty.includes(name) && channel_id ? [] : rules;
    };

    return {
      authOptions,
      translate,
      authFields,
      isOauth,
      isJWT,
      infoAuth,
      confirmOptions,
      checkFileType,
      onFileRejected,
      formRulesConfig,
      ...toRefs(state)
    };
  }
});
