<template>
  <div
    class="q-field items-start"
    :class="{ 'q-field--with-bottom': ShowBottomSpace }"
  >
    <fieldset>
      <slot />
    </fieldset>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Fieldset',
  props: {
    ShowBottomSpace: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
fieldset {
  border: 1px solid #adadad;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0;
  legend {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #606060;
  }
}
</style>
