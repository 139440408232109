





































import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  PropType
} from '@vue/composition-api';
import { Icommon, Iselect } from '@models/general';
import sz_lang from '@helpers/translate';
import { HsmModule } from '@helpers/vuex-composition';
import { getIconPlatform } from '@helpers/utils';

export default defineComponent({
  name: 'SelectChannelDialog',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    platforms: {
      type: Array as PropType<Icommon[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();

    const state = reactive({
      search: ''
    });

    const { loadingSelect, listSelectDevices } = HsmModule.useState([
      'loadingSelect',
      'listSelectDevices'
    ]);

    const {
      GET_SELECT_DEVICES,
      VIRTUAL_SCROLL_SELECT_DEVICES,
      GET_SELECT_DEVICES_SEARCH
    } = HsmModule.useActions([
      'GET_SELECT_DEVICES',
      'VIRTUAL_SCROLL_SELECT_DEVICES',
      'GET_SELECT_DEVICES_SEARCH'
    ]);

    onMounted(async () => {
      await GET_SELECT_DEVICES({ search: '' });
    });

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      }
    });

    const channelsOptions = computed(() => {
      let options = listSelectDevices.value.filter((item: Icommon) => {
        const search = state.search.toLowerCase();
        return item.description.toLowerCase().includes(search);
      });

      return options.map(
        (item: Icommon): Iselect => {
          return {
            icon: getIconPlatform(props.platforms, item.platform),
            label: item.description,
            value: item.number
          };
        }
      );
    });

    const searchChannels = async (search: string, update: any) => {
      state.search = search;

      if (search.length >= 3) {
        await GET_SELECT_DEVICES_SEARCH(search);
      }
      update(() => {
        listSelectDevices.value;
      });
    };

    return {
      model,
      translate,
      channelsOptions,
      searchChannels,
      loadingSelect,
      VIRTUAL_SCROLL_SELECT_DEVICES
    };
  }
});
