var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('accordion',{attrs:{"rounded":"","items":_vm.itemAccordion,"input-style":"max-height:200px;overflow-y:auto;"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-12 text-right"},[_c('sz-btn',{attrs:{"icon":"fas fa-plus fa-lg","size":"sm","data-cy":"plus","unelevated":"","round":"","color":"grey","dense":""},on:{"click":_vm.addHeaders}})],1),_c('div',{staticClass:"col-12"},[_c('simple-table',_vm._b({attrs:{"dense":""},scopedSlots:_vm._u([{key:"body-cell-name",fn:function(ref){
var props = ref.props;
return [_c('sz-input-dense',{attrs:{"outlined":"","dense":""},model:{value:(_vm.headers[props.index].name),callback:function ($$v) {_vm.$set(_vm.headers[props.index], "name", $$v)},expression:"headers[props.index].name"}})]}},{key:"body-cell-value",fn:function(ref){
var props = ref.props;
return [_c('sz-input-dense',{attrs:{"outlined":"","dense":""},model:{value:(_vm.headers[props.index].value),callback:function ($$v) {_vm.$set(_vm.headers[props.index], "value", $$v)},expression:"headers[props.index].value"}})]}},{key:"body-cell-action",fn:function(ref){
var props = ref.props;
return [_c('a',{staticClass:"text-red cursor-pointer",on:{"click":function($event){return _vm.removeHeaders(props.index)}}},[_c('icon',{attrs:{"name":"fas fa-trash-alt fa-lg"}})],1)]}}])},'simple-table',_vm.simpleTable.headers,false))],1)])]},proxy:true},{key:"parameter",fn:function(){return [_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-12 text-right"},[_c('sz-btn',{attrs:{"icon":"fas fa-plus fa-lg","size":"sm","data-cy":"plus","unelevated":"","round":"","color":"grey","dense":""},on:{"click":_vm.addParams}})],1),_c('div',{staticClass:"col-12"},[_c('simple-table',_vm._b({attrs:{"dense":""},scopedSlots:_vm._u([{key:"body-cell-name",fn:function(ref){
var props = ref.props;
return [_c('sz-input-dense',{attrs:{"outlined":"","dense":""},model:{value:(_vm.params[props.index].name),callback:function ($$v) {_vm.$set(_vm.params[props.index], "name", $$v)},expression:"params[props.index].name"}})]}},{key:"body-cell-value",fn:function(ref){
var props = ref.props;
return [_c('sz-input-dense',{attrs:{"outlined":"","dense":""},model:{value:(_vm.params[props.index].value),callback:function ($$v) {_vm.$set(_vm.params[props.index], "value", $$v)},expression:"params[props.index].value"}})]}},{key:"body-cell-action",fn:function(ref){
var props = ref.props;
return [_c('a',{staticClass:"text-red cursor-pointer",on:{"click":function($event){return _vm.removeParams(props.index)}}},[_c('icon',{attrs:{"name":"fas fa-trash-alt fa-lg"}})],1)]}}])},'simple-table',_vm.simpleTable.params,false))],1)])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-12 text-right"},[_c('sz-btn',{attrs:{"icon":"fas fa-plus fa-lg","size":"sm","data-cy":"plus","unelevated":"","round":"","color":"grey","dense":""},on:{"click":_vm.addBody}})],1),_c('div',{staticClass:"col-12"},[_c('simple-table',_vm._b({attrs:{"dense":""},scopedSlots:_vm._u([{key:"body-cell-name",fn:function(ref){
var props = ref.props;
return [_c('sz-input-dense',{attrs:{"outlined":"","dense":""},model:{value:(_vm.body[props.index].name),callback:function ($$v) {_vm.$set(_vm.body[props.index], "name", $$v)},expression:"body[props.index].name"}})]}},{key:"body-cell-value",fn:function(ref){
var props = ref.props;
return [_c('sz-input-dense',{attrs:{"outlined":"","dense":""},model:{value:(_vm.body[props.index].value),callback:function ($$v) {_vm.$set(_vm.body[props.index], "value", $$v)},expression:"body[props.index].value"}})]}},{key:"body-cell-action",fn:function(ref){
var props = ref.props;
return [_c('a',{staticClass:"text-red cursor-pointer",on:{"click":function($event){return _vm.removeBody(props.index)}}},[_c('icon',{attrs:{"name":"fas fa-trash-alt fa-lg"}})],1)]}}])},'simple-table',_vm.simpleTable.body,false))],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }