














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Loading',
  props: {
    indeterminate: {
      type: Boolean,
      default: true
    },
    trackColor: {
      type: String,
      default: 'grey-5'
    },
    size: {
      type: String,
      default: '50px'
    },
    color: {
      type: String,
      default: 'blue'
    }
  }
});
