import { $apollo } from '@boot/apollo';
import { legacyApi, legacySZ } from '@boot/axios';
import { mutation } from '@graphql';
import { setUserCache } from '@helpers/cache';
import { getMessageError, getMessageInvalid } from '@helpers/errors';
import sz_lang from '@helpers/translate';
import {
  getStorage,
  removeSessionUser,
  setStorageLang,
  confirmNotification,
  alertNotification,
  parseStringForJson
} from '@helpers/utils';
import { Icommon } from '@models/general';
import { IRedefinePassword, Isignin } from '@models/model';
import { AxiosResponse } from 'axios';
import { $route } from 'src/router';
import { ActionTree } from 'vuex';

const { translate } = sz_lang();

export const actions: ActionTree<Icommon, any> = {
  async LOGIN({ commit, dispatch }, params: Isignin): Promise<void> {
    commit('SET_SUBMITTING', true);
    commit('SET_BTN_SUBMIT_DISABLE', true);
    return await $apollo
      .mutate({
        mutation: mutation.LOGIN,
        variables: { ...params }
      })
      .then(({ data: { login } }) => {
        if (login.token) {
          setUserCache(login);
          setStorageLang(params.language);
          window.location.href = login.intend;
        } else {
          commit('SET_BTN_SUBMIT_DISABLE', false);
          commit('SET_SUBMITTING', false);
        }

        return Promise.resolve();
      })
      .catch(error => {
        // usuário que era remoto que tentar logar com a senha encaminhar para redefinir senha
        if (error.message === 'user_was_remote_change_email') {
          commit('SET_BTN_SUBMIT_DISABLE', false);
          commit('SET_SUBMITTING', false);
          return $route.push({
            path: 'forgot_password?was_remote=true'
          });
        }

        let response = parseStringForJson(error.message);

        if (typeof response !== 'string') {
          response.message = response.error;
        } else {
          response = error;
        }

        switch (response.message) {
          case 'lock_prediction':
            const lock_prediction = translate.value.signin_message.lock_prediction(
              { days: response.data.numberOfDays || 5 }
            );

            const loginCallback = async (): Promise<void> => {
              await dispatch('LOGIN', { ...params, ignoreBlocked: true });
            };

            alertNotification(loginCallback, {
              message: lock_prediction,
              icon: 'fas fa-exclamation-circle',
              textConfirm: translate.value.aware
            });

            break;
          case 'locked_system':
            const message = translate.value.signin_message.locked_system;

            if (response.data.blockingTime || response.data.isAgent) {
              commit('SET_MESSAGE_ALERT', {
                messageAlert: message,
                isAlertError: true
              });
              return;
            }

            const callback = async (): Promise<void> => {
              await dispatch('UNLOCK_FOR_TRUST', params);
            };

            confirmNotification(callback, {
              message: message,
              icon: 'fas fa-exclamation-circle',
              textConfirm: translate.value.unlock_for_trust,
              textCancel: translate.value.aware
            });

            break;
          case 'login_disabled':
          case 'max_agent_simult':
          case 'out_of_hours':
          case 'empty_campaign':
            commit('SET_MESSAGE_ALERT', {
              messageAlert: getMessageInvalid(response),
              isAlertError: true
            });
            break;
          case 'recaptcha_low_score':
          case 'no_recaptcha_token_response':
          case 'user_or_password_wrong_with_recaptchav2':
          case 'user_blocked_to_access':
            const { host_is_not_recaptcha } = getStorage('sz_whitelabel');

            if (!host_is_not_recaptcha) {
              commit('SET_SHOW_RECAPTCHA_V2', true);
            }
          default:
            commit('SET_MESSAGE_ALERT', {
              messageAlert: getMessageError(response),
              isAlertError: true
            });
            break;
        }

        commit('SET_BTN_SUBMIT_DISABLE', false);
        commit('SET_SUBMITTING', false);

        return Promise.reject(error);
      });
  },
  async REDEFINE_PASSWORD(
    { commit },
    params: IRedefinePassword
  ): Promise<void> {
    commit('SET_SUBMITTING', true);

    await $apollo
      .mutate({
        mutation: mutation.REDEFINE_PASSWORD,
        variables: { ...params }
      })
      .then(({ data }) => {
        const redefinePassword = data.redefinePassword;
        if (redefinePassword.token) {
          setUserCache(redefinePassword);
          setStorageLang(params.language);
          window.location.href = redefinePassword.intend;
        }
      })
      .catch((error: any) => {
        const translates = error?.graphQLErrors[0]?.translates;
        commit('SET_MESSAGE_ALERT', {
          messageAlert: getMessageError(error, translates),
          isAlertError: true
        });
      })
      .finally(() => {
        commit('SET_SUBMITTING', false);
      });
  },
  async FORGOT_PASSWORD({ commit }, params: Icommon) {
    commit('SET_SUBMITTING', true);
    return await legacySZ
      .post('forgot_password/make', params)
      .then((response: AxiosResponse<any>) => {
        commit('SET_FORGOT_PASSWORD_SUCCESS', true);
        commit('SET_MESSAGE_ALERT', {
          messageAlert: translate.value.translateForgotPasswordSuccess({
            email: response?.data?.field
          }),
          isAlertError: false
        });

        return response.data;
      })
      .catch((error: any) => {
        const message = error.response?.data?.errors?.message || false;
        if (message) {
          commit('SET_MESSAGE_ALERT', {
            messageAlert: getMessageError(error.response?.data?.errors),
            isAlertError: true
          });
        }
      })
      .finally(() => {
        commit('SET_SUBMITTING', false);
      });
  },
  async GET_REMOTE_AUTH_PROVIDERS() {
    return await legacyApi
      .get('/remote-auth-idps')
      .then((response: AxiosResponse<any>) => {
        return response.data;
      });
  },
  async GET_CONFIG_APPLICATION() {
    return await legacyApi
      .get('/config-application')
      .then((response: AxiosResponse<any>) => {
        return response.data;
      });
  },
  UNLOCK_FOR_TRUST({dispatch},params: Icommon){
    return legacyApi
      .post('/unlock_for_trust')
      .then(() => {

        const callback = async (): Promise<void> => {
          await dispatch('LOGIN', {...params, ignoreBlocked: true})
        }

        alertNotification(callback, {
          message: translate.value.messages.unlock_for_trust,
          icon: 'fas fa-exclamation-circle',
          textConfirm: translate.value.access_now
        });


      alertNotification(callback, {
        message: translate.value.messages.unlock_for_trust,
        icon: 'fas fa-exclamation-circle',
        textConfirm: translate.value.access_now
      });
    });
  },
  LOGOUT() {
    removeSessionUser();
  },
  PUT_MESSAGE_ALERT({ commit }, message: string) {
    commit('SET_MESSAGE_ALERT', {
      messageAlert: message,
      isAlertError: true
    });
    commit('SET_SHOW_ALERT', true);
  },
  CLEAR_ALERT({ commit }) {
    commit('SET_MESSAGE_ALERT', {
      messageAlert: '',
      isAlertError: false
    });
    commit('SET_SHOW_ALERT', false);
  }
};
