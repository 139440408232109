





import { UnleashClient, IConfig, IContext } from 'unleash-proxy-client';
import {
  defineComponent,
  reactive,
  toRefs,
  provide,
  PropType
} from '@vue/composition-api';
import { Icommon } from '@models/general';

const createProxy = client => {
  return reactive({
    isReady: false,
    isError: false,
    on: (event: string, params: any) => {
      return client.on(event, params);
    },
    isEnabled: (toggleName: string): boolean => {
      return client?.isEnabled(toggleName);
    },
    getVariant: (name: string) => {
      return client?.getVariant(name);
    },
    getAllToggles: (): Icommon[] => {
      return client?.getAllToggles();
    },
    getContext: () => {
      return client?.getContext();
    },
    updateContext: (context: IContext): Icommon => {
      return client?.updateContext(context);
    },
    setContextField: (field, value): Icommon => {
      return client?.setContextField(field, value);
    }
  });
};

export default defineComponent({
  name: 'SzUnleash',
  props: {
    config: {
      type: Object as PropType<IConfig>,
      default: () => {
        return {} as Icommon;
      }
    }
  },
  setup(props) {
    const state: Icommon = reactive({
      client: {}
    });

    state.client = new UnleashClient(props.config as IConfig);
    const proxy = createProxy(state.client);
    provide('unleash', proxy);

    state.client.on('ready', () => (proxy.isReady = true));
    state.client.on('error', e => (proxy.isError = e));

    const host = window.location.host;
    const user_email = localStorage.getItem('user_email');

    state.client.setContextField('host', host);
    state.client.setContextField('email', user_email);

    state.client.start();

    return {
      ...proxy,
      ...toRefs(state)
    };
  }
});
