import { Icommon } from '@models/general';
import { GetterTree } from 'vuex';
import { getClientLanguage } from '@helpers/utils';

export const getters: GetterTree<Icommon, any> = {
  getLang() {
    switch (getClientLanguage()) {
      case 'es':
        return 'es_ES';
      case 'en':
        return 'en_US';
      default:
        return 'pt_BR';
    }
  }
};
