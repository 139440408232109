























import { defineComponent, PropType } from '@vue/composition-api';

import CountryFlag from 'vue-country-flag';
import { Ilanguage } from '@models/general';

export default defineComponent({
  name: 'BtnLanguage',
  components: { CountryFlag },
  props: {
    list: {
      type: Array as () => PropType<[Ilanguage]>,
      default: () => []
    }
  },
  setup(props) {
    return { listLanguage: props.list };
  }
});
