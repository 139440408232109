import { generatePaginate } from '@helpers/utils';
import { Icommon } from '@models/general';

export const state: Icommon = {
  teams: [],
  listSelectTeams: [],
  loadingSelect: false,
  pagination: {
    selectTeam: generatePaginate()
  }
};
