import { Icommon } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_LIST_MARKETPLACE(state, value: Icommon[]) {
    state.listMarketplace = value;
  },
  SET_LOADING_SEARCH(state, value: boolean) {
    state.loadingSearch = value;
  },
  SET_SEND(state, value: Icommon = {}) {
    state.send = { ...value };
  },
  OPEN_MODAL_MARKETPLACE(state, value: Icommon = {}) {
    state.modalMarketplace = {
      openModal: value?.openModal || false,
      integration: value?.integration || {}
    };
  }
};
