import { $apollo } from '@boot/apollo';
import { query } from '@graphql';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';

export const actions: ActionTree<Icommon, any> = {
  async GET_LIST_SELECT_TEAMS({ commit }): Promise<void> {
    commit('SET_LOADING_SELECT', true);
    await $apollo
      .query({ query: query.GET_LIST_SELECT_TEAMS })
      .then(({ data: { teams } }) => {
        commit('SET_LIST_SELECT_TEAMS', teams.items);
        commit('SET_PAGINATION_SELECT_TEAMS', teams.meta);
      })
      .finally(() => {
        commit('SET_LOADING_SELECT', false);
      });
  },
  async SEARCH_TEAMS({ state, commit }, search, searchBy = ''): Promise<void> {
    const searchTeamQuery = searchBy ? query[searchBy] : query.SEARCH_TEAMS;
    if (search && search.length >= 3) {
      await $apollo
        .query({ query: searchTeamQuery, variables: { search } })
        .then(({ data: { teams } }) => {
          teams.items.forEach((team: Icommon) => {
            const index = state.listSelectTeams.findIndex(
              (item: Icommon) => item._id === team._id
            );

            if (index === -1) {
              commit('ADD_ITEM_SELECT_TEAMS', team);
            }
          });
        });
    }
  },
  async VIRTUAL_SCROLL_TEAMS({ state, commit }, { to, ref, direction }) {
    const pagination = state.pagination.selectTeam;
    const lastIndex = state.listSelectTeams.length - 1;
    if (
      state.loadingSelect !== true &&
      direction === 'increase' &&
      to === lastIndex
    ) {
      const limit = pagination.itemsPerPage;
      const page_next = +pagination.currentPage + 1;

      let page = 0;

      if (page_next <= pagination.totalPages) {
        page = page_next;
      } else {
        return false;
      }

      commit('SET_LOADING_SELECT', true);

      await $apollo
        .query({
          query: query.GET_TEAMS_PAGINATION,
          variables: {
            page,
            limit
          }
        })
        .then(({ data: { teams } }) => {
          commit('ADD_LIST_SELECT_TEAMS', teams.items);
          commit('SET_PAGINATION_SELECT_TEAMS', teams.meta);
        })
        .finally(() => {
          commit('SET_LOADING_SELECT', false);
          ref.refresh();
        });
    }
  }
};
