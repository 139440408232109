import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=55d8e190&scoped=true&"
import script from "./Breadcrumb.vue?vue&type=script&lang=ts&"
export * from "./Breadcrumb.vue?vue&type=script&lang=ts&"
import style0 from "./Breadcrumb.vue?vue&type=style&index=0&id=55d8e190&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d8e190",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBreadcrumbs,QBreadcrumbsEl,QSpace});
