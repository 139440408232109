






































































import { IsimplesColumn } from '@models/general';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'SimpleTable',
  props: {
    columns: {
      type: Array as PropType<IsimplesColumn[]>,
      required: true,
      default: () => []
    },
    data: {
      type: Array as PropType<any[]>,
      required: true,
      default: () => []
    },
    headers: {
      type: Array as PropType<IsimplesColumn[]>,
      default: () => []
    },
    separator: {
      type: String,
      default: 'cell'
    },
    flat: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const generateValueTD = computed(() => {
      let config: any = new Object();
      const columns = props.data.map(item => {
        let new_obj: any = new Object();

        props.columns.forEach(column => {
          new_obj[column?.field] = item[column?.field];
          config[column?.field] = column?.align || 'left';
        });

        return new_obj;
      });

      return { columns, config };
    });

    const checkSlotExist = (name: string) => {
      return !!slots[name];
    };

    return {
      checkSlotExist,
      generateValueTD
    };
  }
});
