import useUnleash from './useUnleash';

export function useFeatureFlag() {
  const unleash = useUnleash();

  function isEnabled(flag: string) {
    return unleash.isEnabled(flag);
  }

  return { isEnabled };
}
