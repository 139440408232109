import { Icommon } from '@models/general';
import { MutationTree } from 'vuex';

type MessageAlert = {
  messageAlert: string;
  isAlertError: boolean;
};

export const mutations: MutationTree<Icommon> = {
  SET_SHOW_ALERT(state, value: boolean) {
    state.showAlert = value;
  },
  SET_SUBMITTING(state, value: boolean) {
    state.submitting = value;
  },
  SET_BTN_SUBMIT_DISABLE(state, value: boolean) {
    state.btnSubmitDisable = value;
  },
  SET_MESSAGE_ALERT(state, { isAlertError, messageAlert }: MessageAlert) {
    state.messageAlert = messageAlert;
    state.isAlertError = isAlertError;
    state.showAlert = true;
  },
  SET_FORGOT_PASSWORD_SUCCESS(state, value: boolean) {
    state.isForgotPasswordSuccess = value;
  },
  SET_SHOW_RECAPTCHA_V2(state, value: boolean) {
    state.showRecaptchaV2 = value;
  }
};
