import { Icommon } from '@models/general';

export const state: Icommon = {
  loadingSearch: false,
  listMarketplace: [],
  send: {},
  modalMarketplace: {
    openModal: false,
    integration: {}
  }
};
