import { Icommon } from '@models/general';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';

const TeamModule: Module<Icommon, any> = {
  namespaced: true,
  state,
  mutations,
  actions
};
export default TeamModule;
