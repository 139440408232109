import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';

export const actions: ActionTree<Icommon, any> = {
  GET_PERMISSION_ACTIVE({ getters }, { rules }) {
    const list = Object.keys(rules);
    const permissions: Icommon = getters.getAllPermissions;

    if (permissions.supportRemote) {
      if (permissions.accessConfig) {
        return rules;
      }

      return {};
    }

    if (!permissions.master) {
      const permissionObj = {};
      list.forEach(permission => {
        permissions.permissions.forEach((item: string) => {
          if (item === permission) {
            permissionObj[permission] = item;
          }
        });
      });

      return permissionObj;
    }

    return rules;
  }
};
