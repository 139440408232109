















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NoData',
  props: {
    color: {
      type: String,
      default: 'default'
    },
    icon: {
      type: String,
      default: 'fas fa-info-circle'
    }
  }
});
