

























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Contact',
  props: {
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'blue'
    }
  }
});
