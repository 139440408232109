import { $apollo } from '@boot/apollo';
import { mutation } from '@graphql';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';

export const actions: ActionTree<Icommon, any> = {
  async UPLOAD_FILE({ commit }, payload = {}) {
    commit('SET_LOADING_STORAGE', true);
    return await $apollo
      .mutate({
        mutation: mutation.UPLOAD_FILE,
        variables: { ...payload }
      })
      .finally(() => {
        commit('SET_LOADING_STORAGE', false);
      });
  }
};
