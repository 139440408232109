



























import {
  computed,
  defineComponent,
  PropType,
  WritableComputedRef
} from '@vue/composition-api';
import { IdefinePlatform } from '@models/general';

export default defineComponent({
  name: 'SelectPlatform',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'lang'
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array as PropType<IdefinePlatform[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const labelSelect = computed(() => {
      if (props.label) {
        return props.label;
      }
      return '';
    });

    const settingsOptions = computed(() => {
      if (props.options.length > 0) {
        return props.options;
      }
      return [];
    });

    const model: WritableComputedRef<string> = computed({
      get() {
        return props.value;
      },
      set(value: string) {
        emit('input', value);
      }
    });

    const icon = computed(() => {
      const result = settingsOptions.value.find(
        ({ value }: any) => value === model.value
      );
      return result?.icon || '';
    });

    return {
      labelSelect,
      settingsOptions,
      model,
      icon
    };
  }
});
