











import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Note',
  props: {
    radius: {
      type: String,
      default: '5px'
    },
    color: {
      type: String,
      default: 'default',
      validator: (value: string) => {
        const options = [
          'success',
          'danger',
          'info',
          'purple',
          'warning',
          'default'
        ];

        return options.indexOf(value) !== -1;
      }
    }
  },
  setup(props) {
    const style = computed(() => {
      return { 'border-radius': props.radius };
    });

    return { style };
  }
});
