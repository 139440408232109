



























import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from '@vue/composition-api';
import { AdditionalFieldsModule } from '@helpers/vuex-composition';
import { Icommon, Iselect } from '@models/general';
import sz_lang from '@helpers/translate';

interface Iadditional {
  name: string;
  _id: string;
}

export default defineComponent({
  name: 'SelectAdditionalFields',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    fieldValue: {
      type: String,
      default: '_id'
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    objectValueField: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { translate } = sz_lang();

    const state = reactive({
      search: ''
    });

    const {
      select_additional_fields,
      loadingSelect
    } = AdditionalFieldsModule.useState([
      'select_additional_fields',
      'loadingSelect'
    ]);

    const {
      VIRTUAL_SCROLL_FIELDS,
      SEARCH_FIELDS
    } = AdditionalFieldsModule.useActions([
      'VIRTUAL_SCROLL_FIELDS',
      'SEARCH_FIELDS'
    ]);

    onMounted(() => {
      SEARCH_FIELDS('');
    });

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      }
    });

    const selectLabel = computed(() => {
      if (props.label) {
        return props.label;
      }
      return translate.value.input_fields;
    });

    const fieldsOptions = computed(() => {
      if (props.options?.length > 0) {
        return props.options;
      }

      let listFields = select_additional_fields.value.filter(
        (item: Icommon) => {
          return item.name.toLowerCase().includes(state.search.toLowerCase());
        }
      );

      return listFields.map(
        (item: Iadditional): Iselect => {
          let value = item._id;

          if (props.fieldValue === 'name') {
            value = `{{${item.name}}}`;
          }

          return {
            label: item.name,
            value
          };
        }
      );
    });

    const searchFields = async (search: string, update: any) => {
      state.search = search;

      if (search.length > 3 || search.length === 0) {
        await SEARCH_FIELDS(search);
      }
      update(() => {
        select_additional_fields.value;
      });
    };

    return {
      model,
      fieldsOptions,
      loadingSelect,
      VIRTUAL_SCROLL_FIELDS,
      translate,
      searchFields,
      selectLabel
    };
  }
});
