import { generatePaginate } from '@helpers/utils';
import { Icommon, IPagination } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  ADD_ITEM_SELECT_TEAMS(state, value) {
    state.listSelectTeams.push({ ...value });
  },
  ADD_LIST_SELECT_TEAMS(state, value) {
    state.listSelectTeams = [...state.listSelectTeams, ...value];
  },
  SET_LIST_SELECT_TEAMS(state, value) {
    state.listSelectTeams = value;
  },
  SET_PAGINATION_SELECT_TEAMS(state, value: IPagination) {
    state.pagination.selectTeam = generatePaginate(value);
  }
};
