












































import sz_lang from '@helpers/translate';
import { Ipermission } from '@models/model';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'BtnAction',
  props: {
    visibleRemove: {
      type: Boolean,
      default: true
    },
    visibleEdit: {
      type: Boolean,
      default: true
    },
    permission: {
      type: Object as PropType<Ipermission>,
      default: () => {
        return {
          edit: true,
          remove: true
        };
      }
    }
  },
  setup() {
    const { translate } = sz_lang();

    return { translate };
  }
});
